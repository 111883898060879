//
// user.js
// Use this to write your custom JS
//
const baseUrl = 'https://they.uber.space/cockpit-core/api/content';
const apiKey = 'API-6751a9ef09d34e8295e942466bb3c7618e1ba104';

window.apiClient = {
  async fetchContent(options) {
    const {
      modelName,
      locale,
      isCollection = true, // whether to use items or item, according to whether it is Collection or Singleton
      filter = null,
      sort = null,
    } = options;

    // Build the URL with all possible parameters
    let url = `${baseUrl}/${isCollection ? 'items' : 'item'}/${modelName}`;

    // Add query parameters
    const queryParams = new URLSearchParams();

    // Always add locale if provided
    if (locale) {
      queryParams.append('locale', locale);
    }

    // Add filter if provided
    if (filter) {
      queryParams.append('filter', JSON.stringify(filter));
    }

    if (sort) {
      queryParams.append('sort', JSON.stringify(sort));
    }
    // Append query parameters to URL if we have any
    const queryString = queryParams.toString();
    if (queryString) {
      url += `?${queryString}`;
    }

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'api-key': apiKey,
      },
    });
    return response.json();
  },

  async fetchBothLocales(options) {
    const { modelName, isCollection = true, filter = null, sort = null } = options;

    const userLang = localStorage.getItem('lang') || 'en';
    const otherLang = userLang === 'en' ? 'de' : 'en';

    const [currentLocale, alternateLocale] = await Promise.all([
      this.fetchContent({
        modelName,
        locale: userLang,
        isCollection,
        filter,
        sort,
      }),
      this.fetchContent({
        modelName,
        locale: otherLang,
        isCollection,
        filter,
        sort,
      }),
    ]);

    return {
      [userLang]: currentLocale,
      [otherLang]: alternateLocale,
    };
  },
};
